import * as React from "react"
import BaseLayout from "./../../../components/layout"
import Seo from "./../../../components/seo";
import { Link, graphql, useStaticQuery } from "gatsby";
import HorizontalRule from "./../../../components/horizontal-rule";
import TextBlock from "./../../../components/text-block";
import ArticleImage from "./../../../components/article-image";
import NewsletterSignup from "./../../../components/newsletter-signup";
import BreadcrumbTrail from "../../../components/breadcrumb-trail";


const StoriedScrolls = (props) => {
  let { site } = useStaticQuery(query);

  const aboutText = [
    'Sign up with your email address below to join The Storied Scrolls, my reader group and newsletter.',
    'Members receive updates when I publish new stories, earn free content, and have the opportunity to contribute to the development of my books as beta readers.',
    'My publishing schedule is irregular, but on average I send a few newsletter issues per quarter.',
    'Plus...',
    'If you join today, I will send you a free eBook copy of <a className="text-link" href="/writing/library/tales-of-twilight" target="_blank" rel="noopener noreferrer"><i>Tales of Twilight</i></a>, an eclectic collection of five short stories.'
  ]

  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'The Storied Scrolls',
      }
    ]
  ]

  return (
    <BaseLayout>
      <Seo title="The Storied Scrolls Newsletter" banner="https://media.zackthoutt.com/img/newsletter/newsletter-ad-social.jpeg" pathname={props.location.pathname} breadcrumbList={breadcrumbList}></Seo>
      <div className="layout-base-w app-px-base pt-16 pb-16 md:pb-32">
        <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
        <div className="text-mint">
          <HorizontalRule ruleType="1"></HorizontalRule>
          <h2 className="text-subheader-1 mb-4">Reader Group & Newsletter</h2>
          <h1 className="text-header-1">The Storied Scrolls</h1>
        </div>

        <div className="mx-auto mt-32" style={{'maxWidth': '620px'}}>
          <ArticleImage classString="mb-12" src="https://media.zackthoutt.com/img/newsletter/newsletter-scroll@2x.png" alt="Newsletter scroll graphic"/>
          <TextBlock text={aboutText} textSize="large"></TextBlock>
          <div className="my-8 max-w-md mx-auto">
            <ArticleImage src="https://media.zackthoutt.com/img/tales-of-twilight/tales-of-twilight-marketing@website-seo-image.jpg" alt="Image of Tales of Twilight Book"></ArticleImage>
          </div>

          <div className="mt-20">
            <NewsletterSignup ctaLength="newsletter-page"></NewsletterSignup>
          </div>

          <div className="text-mint my-40">
            <HorizontalRule ruleType="3"></HorizontalRule>
          </div>

        </div>
      </div>
    </BaseLayout>
  )

}

export default StoriedScrolls

const query = graphql`
  query StoriedScrolls {  
    site {
      siteMetadata {
        ...CommonMetaData
        company
      }
    }
  }
`
